.advert_images {
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.image_ad_box {
  position: relative;
  width: 10vw;
  height: 10vw;
}

.remove_image_button {
  position: absolute !important;
  top: 0.3rem;
  right: 0.3rem;
  z-index: 99999;
}
