td {
  vertical-align: middle !important;
  white-space: normal !important;
  font-size: 0.93rem !important;
}

.rs-toast-container .rs-toast-container-top-center {
  z-index: 99999999;
}

.rs-message-container {
  z-index: 99999999;
}
